import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/ship';
import EditModal from './ShipModal';
import ConfDialog from '../../components/ConfDialog';

// ----------------------------------------------------------------------

// 状態、デバイスコード、カラム番号、商品コード、商品名、ロット番号、消費期限、保管場所、個数
const TABLE_HEAD = [
  { id: 'updated_at', label: '日付', alignRight: false },
  { id: 'device_code', label: 'デバイス', alignRight: false },
  { id: 'column_number', label: 'カラム', alignRight: false },
  { id: 'product_code', label: '商品', alignRight: false },
  { id: 'lot_number', label: 'ロット番号', alignRight: false },
  { id: 'expiry_dt', label: '消費期限', alignRight: false },
  { id: 'storage_code', label: '保管庫', alignRight: false },
  { id: 'quantity', label: '数量', alignRight: false },
  { id: 'status', label: '状態', alignRight: false },
  { id: '' }
];

const TABLE_HEAD_MOVE = [
  { id: 'updated_at', label: '日付', alignRight: false },
  { id: 'move_from', label: '移動元', alignRight: false },
  { id: 'storage_code', label: '移動先保管庫', alignRight: false },
  { id: 'product_code', label: '商品', alignRight: false },
  { id: 'lot_number', label: 'ロット番号', alignRight: false },
  { id: 'expiry_dt', label: '消費期限', alignRight: false },
  { id: 'quantity', label: '数量', alignRight: false },
  { id: 'status', label: '状態', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, status, device, storage) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.product_code.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  if (status !== -1) {
    array = filter(array, (_target) => _target.status_label === status);
  }
  if (device !== -1) {
    array = filter(array, (_target) => _target.device_code === device);
  }
  if (storage !== -1) {
    array = filter(array, (_target) => _target.storage_code === storage);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Ship() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modalCollect, setModalCollect] = useState(false);
  const [modalStorage, setModalStorage] = useState(false);
  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [currentTargetId, setCurrentTargetId] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);
  const [modal, setModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});

  // フィルタ用
  const [status, setStatus] = useState(-1);
  const [device, setDevice] = useState(-1);
  const [storage, setStorage] = useState(-1);

  // 移動リスト
  const [listMove, setListMove] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName,
    status,
    device,
    storage
  );

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('ship/list', {}, 'post');
    if (data.result) {
      setList(data.data);
    }
    const { data: dataMove } = await SystemLib.api('storage/moves', {}, 'post');
    if (dataMove.result) {
      console.log(dataMove.data);
      setListMove(dataMove.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id) => {
    const { data } = await SystemLib.api(
      `ship/item`,
      {
        id
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.id === id) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  // const deleteItems = async (ids) => {
  //   const tmp = chunk(ids, 1000);
  //   const p = [];
  //   tmp.forEach(async (arr) => {
  //     console.log(arr);
  //     p.push(
  //       SystemLib.api(
  //         `ship/delete`,
  //         {
  //           ids: arr
  //         },
  //         'post'
  //       )
  //     );
  //   });
  //   await Promise.all(p);
  //   const newItems = list.filter((v) => !ids.includes(v.id));
  //   setList(newItems);
  // };

  const reload = async (id) => {
    // getItem(id);
    getList();
  };

  const classes = useStyles();
  const buttonColors = {
    pre_collect: 'error',
    pre_shipping: 'info',
    shipping: 'success',
    move: 'success'
  };
  const buttonLabels = { pre_collect: '回収完了', pre_shipping: '出荷完了', shipping: '補充完了' };

  return (
    <>
      <Page title="出荷・補充・回収の実施">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              出荷・補充・回収の実施
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfAll(true);
              }}
              onChange={(sel) => {
                setStatus(sel);
              }}
              onChangeDevice={(sel) => {
                setDevice(sel);
              }}
              onChangeStorage={(sel) => {
                setStorage(sel);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          device_code,
                          device_name,
                          column_number,
                          product_code,
                          product_name,
                          lot_number,
                          expiry_dt,
                          quantity,
                          ship_dt,
                          load_dt,
                          sale_dt,
                          status,
                          status_label,
                          storage_code,
                          storage_name,
                          created_at,
                          updated_at
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        let status_color = null;
                        if (status_label.match(/補充/)) {
                          status_color = '#aee6ae';
                        } else if (status_label.match(/回収/)) {
                          status_color = '#fad9d2';
                        } else if (status_label.match(/出荷/)) {
                          status_color = '#a4d7eb';
                        }
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell> */}
                            <TableCell scope="row" padding="normal" sx={{ whiteSpace: 'nowrap' }}>
                              {moment(updated_at).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              sx={{ paddingLeft: 2, whiteSpace: 'nowrap' }}
                            >
                              <Stack direction="column" padding="0" margin="0">
                                {device_code}
                                <br />
                                {device_name}
                              </Stack>
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {column_number}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              sx={{ paddingLeft: 2, whiteSpace: 'nowrap' }}
                            >
                              {product_code}
                              <br />
                              {product_name}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {lot_number}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {expiry_dt}
                            </TableCell>
                            <TableCell scope="row" padding="none" sx={{ paddingLeft: 2 }}>
                              {!status_label.match(/回収/) ? storage_code : null}
                              <br />
                              {!status_label.match(/回収/) ? storage_name : null}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {quantity}
                            </TableCell>
                            <TableCell
                              scope="row"
                              width="100"
                              sx={{
                                backgroundColor: status_color,
                                paddingLeft: 3,
                                whiteSpace: 'nowrap'
                              }}
                              align="center"
                            >
                              {status_label}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="right" width={90}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color={buttonColors[status]}
                                  onClick={async () => {
                                    switch (status) {
                                      case 'pre_collect':
                                        setCurrentTargetId(id);
                                        setCurrentMax(quantity);
                                        setUpdateDt(moment().unix());
                                        // setModal(true);
                                        setConfOk(() => async () => {
                                          const { data } = await SystemLib.api(
                                            'ship/quantity',
                                            {
                                              id,
                                              quantity
                                            },
                                            'post'
                                          );
                                          if (data.result) {
                                            reload();
                                          }
                                        });
                                        setConf(true);
                                        break;
                                      case 'pre_shipping':
                                        setConfOk(() => async () => {
                                          const { data } = await SystemLib.api(
                                            'ship/ship',
                                            { id },
                                            'post'
                                          );
                                          if (data.result) {
                                            reload();
                                          }
                                        });
                                        setConf(true);
                                        break;
                                      case 'shipping':
                                        setConfOk(() => async () => {
                                          const { data } = await SystemLib.api(
                                            'ship/load',
                                            { id },
                                            'post'
                                          );
                                          if (data.result) {
                                            reload();
                                          }
                                        });
                                        setConf(true);
                                        break;
                                      default:
                                        break;
                                    }
                                  }}
                                >
                                  {buttonLabels[status]}
                                </Button>
                              </Stack>
                            </TableCell>
                            <TableCell scope="row" padding="none" align="right" width={100}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  onClick={async () => {
                                    switch (status) {
                                      case 'pre_collect':
                                        setCurrentTargetId(id);
                                        // setModal(true);
                                        setConfOk(() => async () => {
                                          const { data } = await SystemLib.api(
                                            'ship/collectCancel',
                                            { id },
                                            'post'
                                          );
                                          if (data.result) {
                                            reload();
                                          }
                                        });
                                        setConf(true);
                                        break;
                                      case 'pre_shipping':
                                      case 'shipping':
                                        setConfOk(() => async () => {
                                          const { data } = await SystemLib.api(
                                            'ship/shipCancel',
                                            {
                                              id,
                                              status
                                            },
                                            'post'
                                          );
                                          if (data.result) {
                                            reload();
                                          }
                                        });
                                        setConf(true);
                                        break;
                                      default:
                                        break;
                                    }
                                  }}
                                >
                                  キャンセル
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
          <Card style={{ marginTop: 10 }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={null}
                    orderBy={null}
                    headLabel={TABLE_HEAD_MOVE}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={() => {}}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {listMove.map((row) => {
                      const {
                        id,
                        product_code,
                        product_name,
                        lot_number,
                        expiry_dt,
                        quantity,
                        status,
                        storage_code,
                        storage_name,
                        move_from,
                        move_from_name,
                        updated_at
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      let status_color = null;
                      if (status.match(/move/)) {
                        status_color = '';
                      } else if (status.match(/pre_collect/)) {
                        status_color = '#fad9d2';
                      }
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell scope="row" padding="normal" sx={{ whiteSpace: 'nowrap' }}>
                            {moment(updated_at).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell
                            scope="row"
                            padding="none"
                            sx={{ paddingLeft: 2, whiteSpace: 'nowrap' }}
                          >
                            <Stack direction="column" padding="0" margin="0">
                              {move_from}
                              {move_from && <br />}
                              {move_from_name}
                            </Stack>
                          </TableCell>
                          <TableCell scope="row" padding="none" sx={{ paddingLeft: 2 }}>
                            {status === 'pre_collect'
                              ? '廃棄回収'
                              : `${storage_code}\n${storage_name}`}
                          </TableCell>
                          <TableCell
                            scope="row"
                            padding="none"
                            sx={{ paddingLeft: 2, whiteSpace: 'nowrap' }}
                          >
                            {product_code}
                            <br />
                            {product_name}
                          </TableCell>
                          <TableCell scope="row" padding="normal">
                            {lot_number}
                          </TableCell>
                          <TableCell scope="row" padding="normal">
                            {expiry_dt}
                          </TableCell>
                          <TableCell scope="row" padding="normal">
                            {quantity}
                          </TableCell>
                          <TableCell
                            scope="row"
                            width="100"
                            sx={{
                              backgroundColor: status_color,
                              paddingLeft: 3,
                              whiteSpace: 'nowrap'
                            }}
                            align="center"
                          >
                            {status === 'pre_collect' ? '回収指示' : '保管庫移動'}
                          </TableCell>
                          <TableCell scope="row" padding="none" align="right" width={90}>
                            <Stack direction="row" spacing={1} alignItems="center" paddingLeft={1}>
                              <Button
                                size="small"
                                variant="outlined"
                                color={buttonColors[status]}
                                onClick={async () => {
                                  switch (status) {
                                    case 'pre_collect':
                                      setCurrentTargetId(id);
                                      setCurrentMax(quantity);
                                      setUpdateDt(moment().unix());
                                      // setModal(true);
                                      setConfOk(() => async () => {
                                        const { data } = await SystemLib.api(
                                          'ship/storageCollect',
                                          {
                                            id
                                          },
                                          'post'
                                        );
                                        if (data.result) {
                                          reload();
                                        }
                                      });
                                      setConf(true);
                                      break;
                                    case 'move':
                                      setConfOk(() => async () => {
                                        const { data } = await SystemLib.api(
                                          'storage/moved',
                                          { id },
                                          'post'
                                        );
                                        if (data.result) {
                                          reload();
                                        }
                                      });
                                      setConf(true);
                                      break;
                                    default:
                                      break;
                                  }
                                }}
                              >
                                {status === 'pre_collect' ? '回収完了' : '移動完了'}
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
        <EditModal
          targetId={currentTargetId}
          max={currentMax}
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          updateDt={updateDt}
          reload={(id) => {
            reload(id);
          }}
        />
        <ConfDialog
          open={conf}
          onOk={() => {
            confOk();
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            confOk();
            setSelected([]);
            setConfAll(false);
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
